<template>
  <PageContentLayoutOnly>
    <div class="">
      <article class="">
        <div class="container" v-if="contactList != undefined">
          <div v-for="(c, index) in contactList" :key="index">
            <div
              style="padding: 10px; important!;"
              class="card-content tile is-parent notification is-12"
            >
              <div class="tile is-child notification is-10">
                <p class="title is-4">
                  <span>{{ `${c.firstName || ''} ${c.lastName || ''}` }}</span
                  ><span class="float-right">
                    <b-button
                      class="is-small is-rounded font-small is-primary"
                      @click.prevent="
                        confirmSignupContact(c.firstName, c.lastName, c.ownerContactID)
                      "
                      >Sign-up</b-button
                    ><span class="p-1">&nbsp;</span>
                    <b-button
                      class="is-small is-rounded  font-small is-primary"
                      @click.prevent="setUpdateContactModal(c)"
                      >Edit</b-button
                    ><span class="p-1">&nbsp;</span>
                    <b-button
                      class="is-small is-rounded  font-small is-primary"
                      @click.prevent="
                        confirmDeleteOwnerContact(c.firstName, c.lastName, c.ownerContactID)
                      "
                      >Delete</b-button
                    ><span class="p-1">&nbsp;</span>
                  </span>
                </p>
                <p class="subtitle is-6">
                  <span
                    ><label class="label">{{ `Email: ${c.emailAddressOne || ''}` }}</label></span
                  >
                </p>
                <p class="subtitle is-6">
                  <span
                    ><label class="label">{{ `Phone: ${c.phone || ''}` }}</label></span
                  >
                </p>
                <p class="subtitle is-6">
                  <span
                    ><label class="label">{{ `Work: ${c.workPhone || ''}` }}</label></span
                  >
                </p>
                <p class="subtitle is-6">
                  <span
                    ><label class="label">{{ `Mobile: ${c.cellPhone || ''}` }}</label></span
                  ><span
                    class="float-right"
                    v-if="
                      c.hasSignedUpWithOwnerCode &&
                        c.hasSignedUpWithOwnerCode != undefined &&
                        c.hasSignedUpWithOwnerCode == true
                    "
                    ><b-icon icon="key-variant" class="mdi-18px float-right pr-5"
                  /></span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="pt-5 pl-4">
          <div>
            <b-button @click.prevent="setContactModal()" type="button is-primary is-rounded"
              >Add Contact Record</b-button
            >
          </div>
        </div>
      </article>
    </div>
    <Modal :toggle.sync="toggle">
      <ContactModal :toggle="toggle" :ownerID="currentOwnerId" :contact="contact"> </ContactModal>
    </Modal>
  </PageContentLayoutOnly>
</template>

<script>
import { mapGetters } from 'vuex'
import kms from '@/services/kms'
import { notifyError, notifyMessage } from '@/services/notify'
import Modal from '@/components/Modal'
import ContactModal from '@/components/ContactModal'
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'

import moment from 'moment'

export const defaultDateFormat = 'MM/dd/yyyy'

export default {
  name: 'ContactsTab',
  components: {
    PageContentLayoutOnly,
    Modal,
    ContactModal
  },

  data: function() {
    return {
      isDebug: true,
      loading: true,
      toggle: false,
      confirmModal: false,
      formatDateFn: function(value) {
        return value != null ? moment(value, 'YYYY/MM/DD HH:mm:ss').format('MM-DD-YYYY') : ''
      },
      formatFn: function(value) {
        return value != null ? value : ''
      },
      formatNumberFn: function(value) {
        return value != null ? value : 0
      },
      selectedRow: null,
      contactList: [],
      contact: null,
      cols: 2
    }
  },

  created() {},

  computed: {
    ...mapGetters('user', [
      'authUser',
      'retrieveUser',
      'currentHoaId',
      'currentOwnerId',
      'currentUnitId'
    ])
  },

  mounted() {
    this.getRefreshedContacts()
  },

  watch: {
    currentHoaId() {
      this.getRefreshedContacts()
    },
    currentOwnerId() {
      this.getRefreshedContacts()
    },
    toggle() {
      if (this.toggle == false) {
        this.reload()
        this.toggle = false
        if (this.Modal && this.Modal != undefined) {
          this.Modal.$emit('update:toggle', (this.toggle = false))
        }
      }
    }
  },

  methods: {
    confirmDeleteOwnerContact(firstName, lastName, id) {
      this.$buefy.dialog.confirm({
        title: 'Deleting Contact',
        message: `Are you sure you want to <b>delete</b> your "${firstName} ${lastName}" contact?`,
        confirmText: 'Delete Contact',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.deleteOwnerContact(id)
      })
    },
    confirmSignupContact(firstName, lastName, id) {
      this.$buefy.dialog.confirm({
        title: 'Email Sign-up Code',
        message: `Are you sure you want to <b>email</b> a sign-up link to "${firstName} ${lastName}"?`,
        confirmText: 'Email Sign-up Code',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.emailSignup(firstName, lastName, id)
      })
    },
    async reload() {
      await this.getRefreshedContacts()
    },
    setContactModal() {
      this.toggle = true
      this.contact = null
    },
    setUpdateContactModal(selectedContact) {
      this.toggle = true
      this.contact = selectedContact
    },
    async getRefreshedContacts() {
      this.loading = true
      if (this.isDebug == true)
        console.debug('getRefreshedContacts currentOwnerId=' + this.currentOwnerId)

      if (this.currentOwnerId && this.currentOwnerId != undefined) {
        const params = {
          ownerId: this.currentOwnerId
        }

        if (this.isDebug == true) console.debug('params=' + JSON.stringify(params))

        const path = `/Roster/OwnerContact/List`
        await kms
          .get(path, {
            params
          })
          .then(({ results }) => {
            console.debug('contact results=' + JSON.stringify(results))

            if (results) {
              this.contactList = results.filter(
                f => f.firstName && f.firstName != undefined && f.firstName.length > 0
              )

              if (this.isDebug == true) {
                console.debug('filtered contact results=' + JSON.stringify(this.contactList))
              }
            }
          })
      }
      this.loading = false
    },
    async deleteOwnerContact(id) {
      if (id <= 0 || !this.currentOwnerId || this.currentOwnerId == undefined) {
        notifyError('Unable to delete this contact.')
        return
      }

      try {
        this.loading = true
        const data = { ownerContactID: id, forceDelete: false }
        const path = `/Roster/OwnerContact/${id}?forceDelete=false`
        const results = await kms.delete(path, data)

        if (this.isDebug == true) console.debug(JSON.stringify(results))

        if (results.recordID >= 0) {
          this.formComplete = true

          this.getRefreshedContacts()

          notifyMessage(`The selected contact was successfully deleted.`)
        } else {
          notifyError('There was a problem deleting this contact.')
        }
      } catch (e) {
        notifyError(e)
      }

      this.loading = false
    },
    async emailSignup(firstName, lastName, id) {
      if (id <= 0) {
        notifyError('Unable to email sign-up code.')
        return
      }

      try {
        this.loading = true
        const params = { ownerId: this.currentOwnerId, ownerContactID: id }

        if (this.isDebug == true) console.debug('emailSignup=' + JSON.stringify(params))

        const path = `/Roster/OwnerContact/SignUp?ownerId=${params.ownerId}&ownerContactID=${params.ownerContactID}`
        const results = await kms.post(path, params)

        if (this.isDebug == true) console.debug(JSON.stringify(results))

        if (results && results == true) {
          this.getRefreshedContacts()

          notifyMessage(`Successfully e-mailed a signup code to ${firstName} ${lastName}.`)
        } else {
          notifyError('There was a problem sending this contact a sign-up code.')
        }
      } catch (e) {
        notifyError(e)
      }

      this.loading = false
    }
  },

  i18n: {
    messages: {
      en: { contacts: { contactsTitle: 'Contacts' } }
    }
  }
}
</script>

<style lang="scss">
.my-grid-class {
  height: 400px;
}

tbody {
  min-height: 100px !important;
  overflow: auto !important;
}

.my-grid-class {
  height: 400px;
}

tbody {
  min-height: 100px !important;
  overflow: auto !important;
}

.item-container {
  font-weight: bold;
  padding: 2px;
  margin: 2px;
}
</style>
