<template>
  <PageContentLayoutOnly>
    <div class="tile is-12" :style="{ display: 'flex', flexDirection: 'column' }">
      <div class="column is-12">
        <b-button @click.prevent="setVehicleModal()" type="button is-primary" class="is-rounded"
          >Add Vehicle</b-button
        >
      </div>
      <div class="column is-12" v-if="vehicleList && vehicleList != undefined">
        <ModernTable
          :columns="columns"
          :rows="rows"
          :filters="{
            show: false
          }"
          headerColor="#FFF"
        />
      </div>
    </div>
    <Modal :toggle.sync="toggle">
      <VehicleModal :toggle="toggle" :ownerID="currentOwnerId" :vehicle="vehicle"> </VehicleModal>
    </Modal>
  </PageContentLayoutOnly>
</template>

<script>
import { mapGetters } from 'vuex'
import kms from '@/services/kms'
import Modal from '@/components/Modal'
import VehicleModal from '@/components/VehicleModal'
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import ModernTable from '@/components/tables/Modern/Table'
import { notifyError, notifyMessage } from '@/services/notify'
import _get from 'lodash/get'
import { parseDate } from '@/utilities/Date/parse'
import Actions from './component/Actions'

import moment from 'moment'

export const defaultDateFormat = 'MM/dd/yyyy'

export default {
  components: {
    PageContentLayoutOnly,
    Modal,
    VehicleModal,
    ModernTable
  },

  data: function() {
    return {
      isDebug: true,
      loading: true,
      toggle: false,
      confirmModal: false,
      formatDateFn: function(value) {
        return value != null ? moment(value, 'YYYY/MM/DD HH:mm:ss').format('MM-DD-YYYY') : ''
      },
      formatFn: function(value) {
        return value != null ? value : ''
      },
      formatNumberFn: function(value) {
        return value != null ? value : 0
      },
      selectedRow: null,
      vehicleList: [],
      vehicle: null,
      rows: [],
      columns: [
        {
          field: 'id',
          label: 'ID',
          width: '5%',
          numeric: true,
          sortable: true,
          searchable: true
        },
        {
          field: 'year',
          label: 'Year',
          width: '5%',
          sortable: true,
          searchable: true
        },
        {
          field: 'make',
          label: 'Make',
          width: '15%',
          sortable: true,
          searchable: true
        },
        {
          field: 'model',
          label: 'Model',
          width: '15%',
          sortable: true,
          searchable: true
        },
        {
          field: 'color',
          label: 'Color',
          width: '1%',
          numeric: true,
          sortable: true,
          searchable: true
        },
        {
          field: 'license',
          label: 'License',
          width: '1%',
          numeric: true,
          sortable: true,
          searchable: true
        },
        {
          field: 'state',
          label: 'State',
          width: '1%',
          numeric: true,
          sortable: true,
          searchable: true
        },
        {
          field: 'modifiedDate',
          label: 'Modified Date',
          width: '1%',
          numeric: true,
          sortable: true,
          searchable: true
        },
        {
          field: 'actions',
          label: 'Actions',
          width: '25%'
        }
      ]
    }
  },

  created() {},

  computed: {
    ...mapGetters('unit', ['statesList']),
    ...mapGetters('user', ['authUser', 'retrieveUser', 'currentHoaId', 'currentOwnerId'])
  },

  mounted() {
    this.getRefreshedVehicles()
  },

  watch: {
    currentHoaId() {
      this.getRefreshedVehicles()
    },
    currentOwnerId() {
      this.getRefreshedVehicles()
    },
    toggle() {
      if (this.toggle == false) {
        this.getRefreshedVehicles()
        this.toggle = false
        if (this.Modal && this.Modal != undefined) {
          this.Modal.$emit('update:toggle', (this.toggle = false))
        }
      }
    },
    vehicleList() {
      this.updateRows()
    }
  },

  methods: {
    confirmDeleteVehicle(id, year, make, model) {
      this.$buefy.dialog.confirm({
        title: 'Deleting Vehicle',
        message: `Are you sure you want to <b>delete</b> this ${year} ${make} ${model}?`,
        confirmText: 'Delete Vehicle',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.deleteVehicle(id)
      })
    },
    updateRows() {
      const dateFormat = 'LL'

      this.rows = this.vehicleList.map(row => {
        const modifiedDate = parseDate(_get(row, 'modifiedDate', ''), dateFormat)

        const id = _get(row, 'vehicleID', '')
        const year = _get(row, 'year', '')
        const make = _get(row, 'make', '')
        const model = _get(row, 'model', '')

        return {
          id,
          year,
          make,
          model,
          color: _get(row, 'color', ''),
          license: _get(row, 'licensePlateNumber', ''),
          state: _get(row, 'state.name'),
          modifiedDate: {
            date: modifiedDate,
            format: dateFormat
          },
          actions: {
            component: Actions,
            props: {
              vehicleID: id,
              year,
              make,
              model,
              loadUpdateVehicle: _id => {
                console.debug('loadUpdateVehicle id' + _id)
                this.setUpdateVehicleModal(row)
              },
              confirmDeleteVehicle: (_id, _year, _make, _model) => {
                this.confirmDeleteVehicle(_id, _year, _make, _model)
              }
            }
          }
        }
      })
    },
    setVehicleModal() {
      this.toggle = true
      this.vehicle = null
    },
    setUpdateVehicleModal(selectedVehicle) {
      this.toggle = true
      this.vehicle = selectedVehicle
    },
    async getRefreshedVehicles() {
      this.loading = true

      if (this.currentOwnerId && this.currentOwnerId != undefined) {
        const params = {
          ownerID: this.currentOwnerId
        }

        if (this.isDebug == true) console.debug('params=' + JSON.stringify(params))

        const path = `/Roster/Vehicle/List`
        const { results: returned } = await kms.get(path, {
          params
        })

        this.vehicleList = returned

        if (this.isDebug == true) console.debug('results=' + JSON.stringify(this.vehicleList))
      }
      this.loading = false
    },
    async deleteVehicle(id) {
      if (id <= 0) {
        notifyError('Unable to delete this vehicle.')
        return
      }

      try {
        this.loading = true
        const data = { vehicleID: id, forceDelete: false }
        const path = `/Roster/Vehicle/${id}?forceDelete=false`
        const results = await kms.delete(path, data)

        if (this.isDebug == true) console.debug(JSON.stringify(results))

        if (results.recordID >= 0) {
          this.formComplete = true

          this.getRefreshedVehicles()

          notifyMessage(`The selected vehicle was successfully deleted.`)
        } else {
          notifyError('There was a problem deleting this vehicle.')
        }
      } catch (e) {
        notifyError(e)
      }

      this.loading = false
    }
  },

  i18n: {
    messages: {
      en: { vehicles: { vehiclesTitle: 'Vehicles' } }
    }
  }
}
</script>

<style lang="scss">
.my-grid-class {
  height: 400px;
}

tbody {
  min-height: 100px !important;
  overflow: auto !important;
}
</style>
