<template>
  <div>
    <button
      class="button is-info is-light is-small is-outline is-rounded"
      @click="loadUpdateVehicle(vehicleID)"
    >
      Edit
    </button>
    &nbsp;
    <button
      class="button is-info is-light is-small is-outline is-rounded"
      @click="confirmDeleteVehicle(vehicleID, year, make, model)"
    >
      Delete
    </button>
  </div>
</template>

<script>
export default {
  props: {
    vehicleID: Number,
    year: String,
    make: String,
    model: String,
    loadUpdateVehicle: Function,
    confirmDeleteVehicle: Function
  }
}
</script>
