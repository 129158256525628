<template>
  <PageContentLayoutOnly>
    <div
      class="tile is-parent is-12"
      :style="{ display: 'flex', textAlign: 'center', marginTop: '-50px' }"
    >
      <div
        v-if="
          (mailingAddress &&
            mailingAddress != undefined &&
            mailingAddress.addressID &&
            mailingAddress.addressOne) ||
            showAddMailingAddress == true
        "
      >
        <div class="columns">
          <div class="column is-center pt-5 pr-6">
            <button
              type="button"
              :disabled="loading"
              class="button is-text float-right"
              @click.prevent="removeMailingAddress(mailingAddress.addressID)"
            >
              Remove Mailing Address
            </button>
          </div>
        </div>
        <article class="tile is-child notification is-12">
          <ValidationObserver ref="form" v-slot="{ handleSubmit }">
            <transition name="fade" mode="out-in" appear>
              <form
                @submit.prevent="handleSubmit(onFormSubmit)"
                class="form"
                :style="{ textAlign: 'left' }"
              >
                <fieldset
                  class="columns"
                  :style="{
                    ...(breakpoint === 'desktop'
                      ? {}
                      : {
                          width: '100%'
                        })
                  }"
                >
                  <div class="column is-half">
                    <div class="column is-12" :style="inputStyle">
                      <valid-input
                        style="width:50px !important;"
                        name="addressOne"
                        label="Street Address"
                        maxlength="50"
                        type="text"
                        vid="addressOne"
                        placeholder="Street Address"
                        spellcheck="true"
                        aria-label="Year"
                        rules="required|max:255"
                        class="is-small"
                        v-model="formData.AddressOne"
                      />
                    </div>
                    <div class="column is-12" :style="inputStyle">
                      <valid-input
                        style="width:50px !important;"
                        name="addressTwo"
                        label="Street Address Two"
                        maxlength="50"
                        type="text"
                        vid="addressTwo"
                        placeholder="Street Address Two"
                        spellcheck="true"
                        aria-label="Year"
                        rules="max:255"
                        class="is-small"
                        v-model="formData.AddressTwo"
                      />
                    </div>
                    <div class="column is-12" :style="inputStyle" v-if="foreignAddress == true">
                      <valid-input
                        style="width:50px !important;"
                        name="AddressThree"
                        label="Street Address Three"
                        maxlength="255"
                        vid="AddressThree"
                        placeholder="Street Address Three"
                        spellcheck="true"
                        aria-label="Address Address Three"
                        rules="max:255"
                        class="is-small"
                        v-model="formData.AddressThree"
                      />
                    </div>
                  </div>
                  <div class="column is-half">
                    <div class="column is-12" v-if="foreignAddress == false">
                      <valid-input
                        style="width:50px !important;"
                        name="city"
                        label="City"
                        maxlength="50"
                        type="text"
                        vid="city"
                        placeholder="City"
                        spellcheck="true"
                        aria-label="City"
                        rules="max:255"
                        class="is-small"
                        v-model="formData.City"
                      />
                    </div>
                    <div class="column is-12" v-if="statesList && foreignAddress == false">
                      <valid-select
                        label="State"
                        placeholder="States"
                        vid="State"
                        v-model="formData.State"
                      >
                        <option
                          v-for="option in statesList"
                          :value="option.value"
                          :key="option.value"
                          >{{ option.label }}</option
                        >
                      </valid-select>
                    </div>
                    <div class="column is-12" v-if="foreignAddress == false">
                      <valid-input
                        style="width:50px !important;"
                        name="postalCode"
                        label="PostalCode"
                        maxlength="50"
                        type="text"
                        vid="postalCode"
                        placeholder="PostalCode"
                        spellcheck="true"
                        aria-label="PostalCode"
                        rules="max:255"
                        class="is-small"
                        v-model="formData.PostalCode"
                      />
                    </div>
                  </div>
                </fieldset>
                <fieldset class="columns">
                  <div class="column is-half pl-5">
                    <b-checkbox
                      class="has-left-text"
                      v-model="formData.ForeignAddress"
                      @click.native="foreignAddress = !foreignAddress"
                      >Foreign</b-checkbox
                    >
                  </div>
                  <div
                    class="column is-half float-right pr-1"
                    style="padding-right: 20px !important;"
                  >
                    <button
                      type="submit"
                      :disabled="loading"
                      class="button is-primary is-rounded float-right"
                    >
                      Save Changes
                    </button>
                  </div>
                  <ValidationProvider vid="providerErrors" v-slot="{ errors }">
                    <div class="field">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </fieldset>
              </form>
            </transition>
          </ValidationObserver>
        </article>
      </div>
      <div v-else>
        <div class="title">Mailing Address</div>
        <div class="force-center">
          <b-notification type="is-info is-light" has-icon aria-close-label="Close notification">
            HOA communications are currently being sent to the property address
          </b-notification>
          <h5 class="subtitle pb-5">No mailing address on file</h5>
          <div>
            <b-button @click.prevent="showAdd()" type="button is-primary"
              >Add Mailing Address</b-button
            >
          </div>
        </div>
      </div>
    </div>
  </PageContentLayoutOnly>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { ValidationError } from '@/services/errors'
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import kms from '@/services/kms'
import { notifyError, notifyMessage } from '@/services/notify'

import moment from 'moment'

// components
import ValidInput from '@/components/inputs/ValidInput'
import ValidSelect from '@/components/inputs/ValidSelect'

let DEFAULT_FORM_VALUES = {
  ownerId: 0,
  addressID: 0,
  addressOne: '',
  addressTwo: '',
  addressThree: '',
  city: '',
  state: '',
  postalCode: '',
  foreignAddress: false
}

export const defaultDateFormat = 'MM/dd/yyyy'

export default {
  name: 'HomeownerMailingAddressTab',
  components: {
    PageContentLayoutOnly,
    ValidInput,
    ValidSelect,
    ValidationObserver,
    ValidationProvider
  },

  data: function() {
    return {
      isDebug: true,
      formComplete: false,
      formData: {
        ...DEFAULT_FORM_VALUES
      },
      loading: false,
      toggle: false,
      confirmModal: false,
      formatDateFn: function(value) {
        return value != null ? moment(value, 'YYYY/MM/DD HH:mm:ss').format('MM-DD-YYYY') : ''
      },
      formatFn: function(value) {
        return value != null ? value : ''
      },
      formatNumberFn: function(value) {
        return value != null ? value : 0
      },
      selectedRow: null,
      mailingAddress: {},
      showAddMailingAddress: false,
      foreignAddress: false,
      inputStyle: ''
    }
  },

  created() {},

  computed: {
    ...mapState({
      breakpoint: state => state.win.breakpoint
    }),
    ...mapGetters('unit', ['statesList']),
    ...mapGetters('user', ['currentOwnerId', 'currentHoaId'])
  },

  mounted() {
    this.$store.dispatch('unit/getStatesList', {
      useAbbreviated: true
    })

    this.getRefreshedMailingAddress()
  },

  watch: {
    currentHoaId() {
      this.getRefreshedMailingAddress()
    },
    currentOwnerId() {
      this.getRefreshedMailingAddress()
    },
    foreignAddress() {
      this.processForeignAddress()
    }
  },

  methods: {
    confirmDeleteMailingAddress(id) {
      this.$buefy.dialog.confirm({
        title: 'Deleting Mailing Address',
        message: `Are you sure you want to <b>delete</b> this mailing address?`,
        confirmText: 'Delete Mailing Address',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.deleteMailingAddress(id)
      })
    },

    reload() {
      this.getRefreshedMailingAddress()
    },

    showAdd() {
      this.showAddMailingAddress = true
    },

    processForeignAddress() {
      if (this.foreignAddress == true) {
        this.inputStyle = 'min-width: 600px !important;'
      } else {
        this.inputStyle = ''
      }
    },

    async getRefreshedMailingAddress() {
      this.loading = true

      if (this.currentOwnerId && this.currentOwnerId != undefined) {
        const path = `/Roster/Owner/${this.currentOwnerId}`
        const result = await kms.get(path)

        if (result && result != undefined) {
          console.log('mailing address result = ' + JSON.stringify(result))
          this.mailingAddress = result.mailingAddress

          this.formData.AddressOne = result.mailingAddress.addressOne
          this.formData.AddressTwo = result.mailingAddress.addressTwo
          this.formData.AddressThree = result.mailingAddress.addressThree
          this.formData.City = result.mailingAddress.city
          this.formData.State = result.mailingAddress.state
          this.formData.PostalCode = result.mailingAddress.postalCode
          this.formData.addressID = this.mailingAddress.addressID
          this.formData.ownerID = this.currentOwnerId
          this.formData.ForeignAddress = this.mailingAddress.foreignAddress
          this.foreignAddress = this.formData.ForeignAddress
        }

        this.processForeignAddress()

        if (this.isDebug == true)
          console.debug('filtered mailing address result=' + JSON.stringify(this.mailingAddress))
      }
      this.showAddMailingAddress = false

      this.loading = false
    },

    async removeMailingAddress(id) {
      if (id === undefined || !id | !this.currentOwnerId) {
        notifyError('Unable to remove this mailing address.')
        return
      }

      const payload = {
        addressID: null,
        addressOne: null
      }

      this.loading = true

      try {
        const path = `/Roster/Owner/UpdateMailingAddress?ownerID=${this.currentOwnerId}`
        let results = null

        results = await kms.put(path, payload)

        if (this.isDebug == true) console.debug(JSON.stringify(results))

        if (results && results.ownerID) {
          notifyMessage(`The mailing address was successfully removed.`)
          setTimeout(() => {
            this.getRefreshedMailingAddress()
          }, 2000)
        } else {
          notifyError('There was a problem removing the mailing address.')
        }
      } catch (e) {
        notifyError(e)
      }

      this.loading = false
    },

    getPayload() {
      if (this.isDebug == true) console.debug('this.formData=' + JSON.stringify(this.formData))

      const payload = {
        addressID: this.formData.addressID,
        addressOne:
          this.formData.AddressOne && this.formData.AddressOne != undefined
            ? this.formData.AddressOne
            : null,
        addressTwo:
          this.formData.AddressTwo && this.formData.AddressTwo != undefined
            ? this.formData.AddressTwo
            : null,
        addressThree:
          this.formData.AddressThree &&
          this.formData.AddressThree != undefined &&
          this.foreignAddress == true
            ? this.formData.AddressThree
            : null,
        city:
          this.formData.City && this.formData.City != undefined && this.foreignAddress == false
            ? this.formData.City
            : null,
        state:
          this.formData.State && this.formData.State != undefined && this.foreignAddress == false
            ? this.formData.State
            : null,
        postalCode:
          this.formData.PostalCode &&
          this.formData.PostalCode != undefined &&
          this.foreignAddress == false
            ? this.formData.PostalCode
            : null,
        foreignAddress: this.foreignAddress,
        ownerID: this.currentOwnerId
      }

      if (this.isDebug == true) console.debug('payload=' + JSON.stringify(payload))

      return payload
    },
    async onFormSubmit() {
      if (
        !this.formData ||
        this.formData == undefined ||
        !this.currentOwnerId ||
        this.currentOwnerId == undefined ||
        this.currentOwnerId <= 0
      ) {
        notifyError('There was a problem submitting your form.')
        return
      }

      this.loading = true
      try {
        const path = `/Roster/Owner/UpdateMailingAddress?ownerID=${this.currentOwnerId}`
        let results = null

        results = await kms.put(path, this.getPayload())

        if (this.isDebug == true) console.debug(JSON.stringify(results))

        if (results && results.ownerID) {
          notifyMessage(`The mailing address was successfully saved.`)
          this.getRefreshedMailingAddress()
        } else {
          notifyError('There was a problem saving the mailing address.')
        }
      } catch (e) {
        // If this is a validation error, get the details for each field
        if (e instanceof ValidationError) {
          this.$refs.form.setErrors(e.fields)
        }

        notifyError('There was a problem saving the mailing address.')
      }
      this.loading = false
      this.toggle = false
      this.formComplete = true
      this.formData = { ...DEFAULT_FORM_VALUES }
      if (this.$refs.form) {
        this.$refs.form.reset()
      }
    }
  },

  i18n: {
    messages: {
      en: { mailingAddress: { mailingAddressTitle: 'Mailing Address' } }
    }
  }
}
</script>

<style lang="scss">
.my-grid-class {
  height: 400px;
}

tbody {
  min-height: 100px !important;
  overflow: auto !important;
}

.my-grid-class {
  height: 400px;
}

tbody {
  min-height: 100px !important;
  overflow: auto !important;
}

.col {
  margin: 2px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  max-width: 50% !important;
}

.item-container {
  font-weight: bold;
  padding: 2px;
  margin: 2px;
}
</style>
