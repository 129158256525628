/*
	import { homeownerDirectoryContact } from '@/services/HomeownerDirectoryContact/store';
*/

import Vue from 'vue'
import Vuex from 'vuex'
import kms from '@/services/kms'
import { notifyProblem, notifyMessage, notifyError } from '@/services/notify'

const isDebug = true

/* eslint-disable no-empty-pattern */
const actions = {
  /*
    const { list } = await homeownerDirectoryContact.dispatch ('getDirectoryContactList', {
      hoaId
    });
  */

  //Homeowner Directory Contact List
  async getDirectoryContactList({}, { params }) {
    console.log('in getDirectoryContactList...')
    try {
      const result = await kms.get('/Roster/HomeownerDirectoryContact/List', {
        params
      })

      if (isDebug == true) console.debug('getDirectoryContactList log=' + JSON.stringify(result))

      return {
        list: result.results
      }
    } catch (exception) {
      notifyProblem(`The directory contact list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  //Get by Id
  async getDirectoryContactById({}, { directoryContactID }) {
    try {
      console.debug('in getDirectoryContactById...')
      const directoryContact = await kms.get(
        `/Roster/HomeownerDirectoryContact/${directoryContactID}`
      )

      if (isDebug == true)
        console.debug('getDirectoryContactById=' + JSON.stringify(directoryContact))

      return {
        result: directoryContact
      }
    } catch (exception) {
      notifyProblem(`There was a problem retrieving the directory contact.`)
      console.error(exception)
    }

    return {
      result: {}
    }
  },

  // Update Directory Contact
  async updateDirectoryContact({ dispatch }, { payload = {}, done }) {
    try {
      const results = await kms.put(`/Roster/HomeownerDirectoryContact`, payload)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.homeownerDirectoryContactID > 0) {
        notifyMessage(`Successfully updated the directory contact.`)
        done()
      } else {
        notifyError('There was a problem updating this directory contact.')
      }
    } catch (exception) {
      notifyProblem(
        `There was a problem processing the update for this directory contact.` + exception.message
      )
      console.error(exception)
    }
  },

  // Add Directory Contact
  async addDirectoryContact(
    { dispatch },
    { ownerID, displayName, phone1, phone2, emailAddress1, emailAddress2, isSharing, done }
  ) {
    if (isDebug == true) console.debug('...in addOwnerPaymentMethod')
    try {
      const results = await kms.post(`/Roster/HomeownerDirectoryContact`, {
        ownerID,
        displayName,
        phone1,
        phone2,
        emailAddress1,
        emailAddress2,
        isSharing
      })

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.homeownerDirectoryContactID > 0) {
        notifyMessage(`Successfully added the directory contact.`)
        done({ details: results })
      } else {
        notifyError('There was a problem adding this directory contact.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem adding this directory contact.`)
      console.error(exception)
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const homeownerDirectoryContact = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
