<template>
  <PageContentLayoutOnly role="region" :aria-label="$t('profile.title')">
    <div class="notification headerColor box is-4" v-if="authUser">
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <transition name="fade" mode="out-in" appear>
          <form @submit.prevent="handleSubmit(onFormSubmit)" class="form">
            <fieldset>
              <div class="form-grid">
                <div class="column is-12">
                  <valid-input
                    label="Display Name"
                    name="DisplayName"
                    type="text"
                    vid="displayName"
                    placeholder="Display Name"
                    spellcheck="true"
                    maxlength="255"
                    rules="required|max:255"
                    v-model="formData.displayName"
                  />
                </div>
                <div class="column is-12">
                  <valid-input
                    label="Phone One"
                    name="phone1"
                    maxlength="20"
                    type="phone"
                    vid="phone1"
                    placeholder="Phone One"
                    spellcheck="true"
                    rules="max:20"
                    v-model="formData.phone1"
                  />
                </div>
                <div class="column is-12">
                  <valid-input
                    label="Phone Two"
                    name="phone2"
                    maxlength="20"
                    type="phone"
                    vid="phone2"
                    placeholder="Phone Two"
                    spellcheck="true"
                    rules="max:20"
                    v-model="formData.phone2"
                  />
                </div>
                <div class="column is-12">
                  <valid-input
                    label="E-mail One"
                    name="email1"
                    maxlength="66"
                    type="email"
                    vid="email1"
                    placeholder="E-mail One"
                    spellcheck="true"
                    rules="max:66"
                    v-model="formData.email1"
                  />
                </div>
                <div class="column is-12">
                  <valid-input
                    label="E-mail Two"
                    name="email2"
                    maxlength="66"
                    type="email"
                    vid="email2"
                    placeholder="E-mail Two"
                    spellcheck="true"
                    rules="max:66"
                    v-model="formData.email2"
                  />
                </div>
                <div class="column is-12 pl-5">
                  <b-checkbox class="has-left-text" v-model="formData.isSharing"
                    >Share This Information with other Homeowners</b-checkbox
                  >
                </div>
              </div>
            </fieldset>
            <fieldset>
              <ValidationProvider vid="providerErrors" v-slot="{ errors }">
                <div class="field">
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
              <button type="submit" class="button is-primary is-rounded" :disabled="loading">
                Save
              </button>
            </fieldset>
          </form>
        </transition>
      </ValidationObserver>
    </div>
  </PageContentLayoutOnly>
</template>

<script>
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import { mapGetters } from 'vuex'
import ValidInput from '@/components/inputs/ValidInput'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

import { data } from './keys/data'
import { methods } from './keys/methods'

export default {
  components: {
    PageContentLayoutOnly,
    ValidInput,
    ValidationObserver,
    ValidationProvider
  },

  data,

  computed: {
    ...mapGetters('user', ['authUser', 'retrieveUser', 'currentHoaId', 'currentOwnerId'])
  },

  mounted() {
    this.loading = true

    this.reload()

    this.loading = false
  },

  watch: {
    hoaId() {
      this.reload()
    }
  },

  methods,

  i18n: {
    messages: {
      en: { profile: { title: 'Profile' } }
    }
  }
}
</script>
