import { mapActions } from 'vuex'
import { notifyError } from '@/services/notify'
import { homeownerDirectoryContact } from '@/services/HomeownerDirectoryContact/store'
import _get from 'lodash/get'

let DEFAULT_FORM_VALUES = {
  displayName: '',
  phone1: '',
  phone2: '',
  emailAddress1: '',
  emailAddress2: '',
  isSharing: false,
  homeownerDirectoryContactID: 0
}

export const methods = {
  ...mapActions('user', ['selectHoaId', 'userRetrieve']),

  async reload() {
    this.loading = true

    this.formData = { ...DEFAULT_FORM_VALUES }
    if (this.$refs.form) {
      this.$refs.form.reset()
    }

    this.loadDirectoryContact()

    if (this.isDebug == true) console.debug('PROFILE authUser=' + JSON.stringify(this.authUser))

    this.loading = false
  },

  async loadDirectoryContact() {
    this.formData.displayName = _get(this, 'authUser.name', null)

    console.log('this.formData.displayName ' + this.formData.displayName)

    this.hasExistingDirectoryContact = false

    //Load existing
    await homeownerDirectoryContact
      .dispatch('getDirectoryContactList', {
        params: {
          ownerID: this.currentOwnerId
        }
      })
      .then(({ list }) => {
        if (list) {
          const exists = list[0]
          if (exists) {
            this.formData.displayName = exists.displayName || ''
            this.formData.phone1 = exists.phone1 || ''
            this.formData.phone2 = exists.phone2 || ''
            this.formData.email1 = exists.emailAddress1 || ''
            this.formData.email2 = exists.emailAddress2 || ''
            this.formData.homeownerDirectoryContactID = exists.homeownerDirectoryContactID || 0
            this.formData.isSharing = exists.isSharing || false
            this.hasExistingDirectoryContact = true
          }
        }
      })
  },

  async onFormSubmit() {
    if (!this.formData || this.formData == undefined) {
      notifyError('There was a problem saving your directory contact information.')
      return
    }

    this.loading = true
    console.debug('payload formData= ' + JSON.stringify(this.formData))

    if (
      this.formData.homeownerDirectoryContactID &&
      this.formData.homeownerDirectoryContactID > 0
    ) {
      homeownerDirectoryContact.dispatch('updateDirectoryContact', {
        payload: {
          homeownerDirectoryContactID: this.formData.homeownerDirectoryContactID || 0,
          ownerID: this.currentOwnerId,
          displayName: this.formData.displayName || '',
          phone1: this.formData.phone1 || '',
          phone2: this.formData.phone2 || '',
          emailAddress1: this.formData.email1 || '',
          emailAddress2: this.formData.email2 || '',
          isSharing: this.formData.isSharing || false
        },
        done: () => {}
      })
    } else {
      homeownerDirectoryContact.dispatch('addDirectoryContact', {
        ownerID: this.currentOwnerId,
        displayName: this.formData.displayName || '',
        phone1: this.formData.phone1 || '',
        phone2: this.formData.phone2 || '',
        emailAddress1: this.formData.email1 || '',
        emailAddress2: this.formData.email2 || '',
        isSharing: this.formData.isSharing || false,
        done: ({ details }) => {
          if (details) {
            if (this.isDebug == true) console.debug('details=' + JSON.stringify(details))
          }
        }
      })
    }

    this.loading = false
  }
}
