<template>
  <div class="section">
    <div class="container">
      <div class="is-size-4">{{ contact !== undefined && contact ? 'Update' : 'Add' }} Contact</div>
      <div class="box is-12">
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
          <transition name="fade" mode="out-in" appear>
            <form @submit.prevent="handleSubmit(onFormSubmit)" class="form">
              <fieldset>
                <div class="column is-12">
                  <input type="hidden" vid="vehicleID" v-model="formData.ownerContactID" />
                  <valid-input
                    style="width:50px !important;"
                    name="FirstName"
                    label="First Name"
                    maxlength="100"
                    type="text"
                    vid="FirstName"
                    placeholder="First Name"
                    spellcheck="true"
                    aria-label="First Name"
                    rules="max:100"
                    class="is-small"
                    v-model="formData.FirstName"
                  />
                </div>
                <div class="column is-12">
                  <valid-input
                    style="width:50px !important;"
                    name="LastName"
                    label="Last Name"
                    maxlength="100"
                    type="text"
                    vid="LastName"
                    placeholder="Last Name"
                    spellcheck="true"
                    aria-label="Last Name"
                    rules="max:100"
                    class="is-small"
                    v-model="formData.LastName"
                  />
                </div>
                <div class="column is-12">
                  <valid-input
                    style="width:50px !important;"
                    name="EmailAddress1"
                    label="E-mail"
                    maxlength="255"
                    type="text"
                    vid="EmailAddressOne"
                    placeholder="E-mail"
                    spellcheck="true"
                    aria-label="E-mail"
                    rules="max:255"
                    class="is-small"
                    v-model="formData.EmailAddressOne"
                  />
                </div>
                <div class="column is-12">
                  <valid-input
                    style="width:50px !important;"
                    name="Phone"
                    label="Phone"
                    maxlength="20"
                    type="phone"
                    vid="Phone"
                    placeholder="Phone"
                    spellcheck="true"
                    aria-label="Phone"
                    rules="max:20"
                    class="is-small"
                    v-model="formData.Phone"
                  />
                </div>
                <div class="column is-12">
                  <valid-input
                    style="width:50px !important;"
                    name="WorkPhone"
                    label="Work"
                    maxlength="20"
                    type="phone"
                    vid="WorkPhone"
                    placeholder="Work"
                    spellcheck="true"
                    aria-label="Work Phone"
                    rules="max:20"
                    class="is-small"
                    v-model="formData.WorkPhone"
                  />
                </div>
                <div class="column is-12">
                  <valid-input
                    style="width:50px !important;"
                    name="CellPhone"
                    label="Mobile"
                    maxlength="20"
                    type="phone"
                    vid="CellPhone"
                    placeholder="Mobile"
                    spellcheck="true"
                    aria-label="Mobile"
                    rules="max:20"
                    class="is-small"
                    v-model="formData.CellPhone"
                  />
                </div>
                <div class="pb-5">
                  <button type="submit" :disabled="loading" class="button is-primary is-rounded">
                    {{ buttonText }}
                  </button>
                </div>
              </fieldset>
              <fieldset>
                <ValidationProvider vid="providerErrors" v-slot="{ errors }">
                  <div class="field">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </fieldset>
            </form>
          </transition>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
// Components
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { ValidationError } from '@/services/errors'
import { notifyMessage, notifyError } from '@/services/notify'
import kms from '@/services/kms'
import { mapState } from 'vuex'
import $ from 'jquery'

// components
import ValidInput from '@/components/inputs/ValidInput'

import moment from 'moment'

let DEFAULT_FORM_VALUES = {
  ownerContactID: 0,
  ownerID: 0,
  firstName: '',
  lastName: '',
  emailAddressOne: '',
  phone: '',
  workPhone: '',
  cellPhone: ''
}

export const defaultDateFormat = 'MM/dd/yyyy'

export default {
  name: 'OwnerContactModal',

  props: {
    ownerID: Number,
    contact: Object
  },

  components: {
    ValidInput,
    ValidationObserver,
    ValidationProvider
  },

  data: function() {
    return {
      formComplete: false,
      formData: {
        ...DEFAULT_FORM_VALUES
      },
      loading: false,
      formatDateFn: function(value) {
        return value != null ? moment(value, 'YYYY/MM/DD HH:mm:ss').format('MM-DD-YYYY') : ''
      },
      isDebug: true,
      selectedRow: null,
      buttonText: ''
    }
  },

  created() {},

  computed: {
    ...mapState({
      unitId: state => state.user.selectedUnitId
    })
  },

  mounted() {
    this.loading = true
    if (this.contact && this.contact != undefined) {
      if (this.isDebug == true) console.debug('contact=' + JSON.stringify(this.contact))

      //populate the form
      this.formData.ownerContactID = this.contact.ownerContactID
      this.formData.ownerID = this.contact.ownerID
      this.formData.FirstName = this.contact.firstName
      this.formData.LastName = this.contact.lastName
      this.formData.EmailAddressOne = this.contact.emailAddressOne
      this.formData.Phone = this.contact.phone
      this.formData.WorkPhone = this.contact.workPhone
      this.formData.CellPhone = this.contact.cellPhone

      this.buttonText = 'Update'
    } else {
      this.formData = { ...DEFAULT_FORM_VALUES }
      this.$refs.form.reset()

      this.buttonText = 'Add'
    }

    this.loading = false
  },

  methods: {
    getPayload() {
      if (this.isDebug == true) console.debug('this.formData=' + JSON.stringify(this.formData))

      const payload = {
        ownerContactID: this.formData.ownerContactID,
        ownerID: this.ownerID,
        firstName: this.formData.FirstName,
        lastName: this.formData.LastName,
        emailAddressOne: this.formData.EmailAddressOne,
        phone: this.formData.Phone,
        workPhone: this.formData.WorkPhone,
        cellPhone: this.formData.CellPhone
      }

      if (this.isDebug == true) console.debug('payload=' + JSON.stringify(payload))

      return payload
    },
    async onFormSubmit() {
      if (
        !this.formData ||
        this.formData == undefined ||
        !this.ownerID ||
        this.ownerID == undefined ||
        this.ownerID <= 0
      ) {
        notifyError('There was a problem submitting your form.')
        return
      }

      if (
        !this.formData.WorkPhone &&
        !this.formData.Phone &&
        !this.formData.CellPhone &&
        !this.formData.EmailAddressOne
      ) {
        notifyError(
          'You must enter at least one of the following to proceed, "Email address, work phone, home phone, or mobile phone".'
        )
        return
      }

      this.loading = true
      try {
        const path = `/Roster/OwnerContact`
        let results = null

        let message = ''

        if (this.formData.ownerContactID > 0) {
          results = await kms.put(path, this.getPayload())
          message = 'updated'
        } else {
          results = await kms.post(path, this.getPayload())
          message = 'added'
        }

        if (this.isDebug == true) console.debug(JSON.stringify(results))

        if (results && results.ownerContactID >= 0) {
          this.$emit('update:toggle', (this.toggle = !this.toggle))

          // auto-close modal
          $('#clickclose').click()

          notifyMessage(`The selected contact was successfully ${message}.`)
        } else {
          notifyError('There was a problem saving this contact.')
        }
      } catch (e) {
        // If this is a validation error, get the details for each field
        if (e instanceof ValidationError) {
          this.$refs.form.setErrors(e.fields)
        }

        notifyError('There was a problem saving this contact.')
      }
      this.loading = false
      this.toggle = false
      this.formComplete = true
      this.formData = { ...DEFAULT_FORM_VALUES }
      if (this.$refs.form) {
        this.$refs.form.reset()
      }
    }
  }
}
</script>

<style lang="scss">
.step-details {
  background-color: #fff !important;
  padding-left: 0.25rem !important;
  padding-right: 0.5rem !important;
}

.b-steps.is-small .steps {
  margin-top: 1rem;
}

.b-steps .steps + .step-content {
  padding-top: 0 !important;
}

.step-navigation {
  display: flex;
  justify-content: flex-end;
}

.my-grid-class {
  height: 400px;
}

tbody {
  min-height: 100px !important;
  overflow: auto !important;
}
</style>
