<template>
  <div class="section">
    <div class="container">
      <div class="is-size-4">
        Edit Mail Delivery<span class="font-small is-size-6">{{
          ` - ${mailDelivery.mailingType.name}`
        }}</span>
      </div>
      <div class="box is-12">
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
          <transition name="fade" mode="out-in" appear>
            <form @submit.prevent="handleSubmit(onFormSubmit)" class="form">
              <fieldset>
                <div v-if="deliveryTypes">
                  <b-select
                    label="Deliver By"
                    placeholder="Deliver By"
                    vid="deliverBy"
                    v-model="formData.deliverBy"
                    title="Deliver By"
                    rules="required"
                    required
                  >
                    <option
                      v-for="option in deliveryTypes"
                      :value="option.value"
                      :key="option.value"
                      >{{ option.label }}</option
                    >
                  </b-select>
                </div>
                <div class="pt-5 pb-5" v-if="showMail === true">
                  <div v-if="mailAddressTypes">
                    <b-select
                      label="<- Select Deliver To Address ->"
                      placeholder="<- Select Deliver To Address ->"
                      vid="deliverToAddress"
                      v-model="formData.deliverToAddress"
                      title="Deliver To Address"
                      rules="required"
                      required
                    >
                      <option
                        v-for="option in mailAddressTypes"
                        :value="option"
                        :key="option.value"
                        >{{ option.label }}</option
                      >
                    </b-select>
                  </div>
                </div>
                <div v-if="showEmailContacts === true">
                  <div class="pt-5">
                    <b-field label="Email to Contacts" tabindex="0" aria-label="Email to Contacts">
                      <div v-if="emailContacts && emailContacts != undefined">
                        <div
                          class="column is-12 pl-5"
                          v-for="(item, index) in emailContacts"
                          :key="index"
                        >
                          <b-checkbox
                            v-if="
                              item.ownerContact &&
                                item.ownerContact !== undefined &&
                                item.ownerContact.ownerContactID &&
                                item.ownerContact.ownerContactID !== undefined
                            "
                            v-model="checkboxTextGroup"
                            :native-value="item.ownerContact.ownerContactID"
                          >
                            {{ item.ownerContact.emailAddressOne || '' }}
                          </b-checkbox>
                        </div>
                      </div>
                    </b-field>
                  </div>
                </div>
                <div class="pb-5">
                  <button type="submit" :disabled="loading" class="button is-primary is-rounded">
                    {{ buttonText }}
                  </button>
                </div>
              </fieldset>
              <fieldset>
                <ValidationProvider vid="providerErrors" v-slot="{ errors }">
                  <div class="field">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </fieldset>
            </form>
          </transition>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
// Components
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { ValidationError } from '@/services/errors'
import { notifyMessage, notifyError } from '@/services/notify'
import { mapState } from 'vuex'
import $ from 'jquery'
import { ownerMailDeliveryStore } from '@/services/OwnerMailingDeliveryMethod/store'
import parseAddress from '@/utilities/address/parse'
import moment from 'moment'

let DEFAULT_FORM_VALUES = {
  deliverBy: 'mail'
}

export const defaultDateFormat = 'MM/dd/yyyy'

export default {
  name: 'MailDeliveryModal',

  props: {
    ownerID: Number,
    mailDelivery: Object
  },

  components: {
    ValidationObserver,
    ValidationProvider
  },

  data: function() {
    return {
      formComplete: false,
      formData: {
        ...DEFAULT_FORM_VALUES
      },
      loading: false,
      formatDateFn: function(value) {
        return value != null ? moment(value, 'YYYY/MM/DD HH:mm:ss').format('MM-DD-YYYY') : ''
      },
      isDebug: true,
      selectedRow: null,
      buttonText: '',
      deliveryTypes: [],
      mailAddressTypes: [],
      emailContacts: [],
      showEmailContacts: false,
      showMail: true,
      checkboxTextGroup: []
    }
  },

  watch: {
    'formData.deliverBy'() {
      if (this.formData.deliverBy === 'mail') {
        this.loadMailAddressTypes()
        this.showEmailContacts = false
        this.showMail = true
      } else if (this.formData.deliverBy === 'email') {
        this.loadEmailContacts()
        this.showEmailContacts = true
        this.showMail = false
      }
    }
  },

  created() {},

  computed: {
    ...mapState({
      unitId: state => state.user.selectedUnitId
    })
  },

  async mounted() {
    this.loading = true

    await this.loadDeliveryTypes()

    await this.loadMailAddressTypes()

    if (this.mailDelivery && this.mailDelivery != undefined) {
      if (this.isDebug == true) console.debug('mailDelivery=' + JSON.stringify(this.mailDelivery))

      this.mailDelivery.deliveryType
      {
        this.formData.deliverBy = this.mailDelivery.deliveryType
      }

      if (this.mailDelivery.computedAddress) {
        //Select address with traversal and indexof
        Array.from(this.mailAddressTypes).forEach(f => {
          if (f) {
            if (
              f.value &&
              f.value !== undefined &&
              this.mailDelivery.computedAddress &&
              this.mailDelivery.computedAddress !== undefined
            ) {
              if (f.value.indexOf(this.mailDelivery.computedAddress.addressOne) > -1) {
                this.formData.deliverToAddress = f
              }
            }
          }
        })
      }

      this.buttonText = 'Update'
    } else {
      this.formData = { ...DEFAULT_FORM_VALUES }
      this.$refs.form.reset()

      this.buttonText = 'Add'
    }

    this.loading = false
  },

  methods: {
    async loadDeliveryTypes() {
      await ownerMailDeliveryStore.dispatch('getDeliveryTypeList').then(({ list }) => {
        if (list) {
          this.deliveryTypes = list

          if (this.isDebug == true)
            console.debug('deliveryTypes=' + JSON.stringify(this.deliveryTypes))
        }
      })
    },
    async loadMailAddressTypes() {
      this.mailAddressTypes = []

      await ownerMailDeliveryStore
        .dispatch('getMailAddressTypesList', {
          ownerID: this.ownerID
        })
        .then(({ list }) => {
          if (list) {
            Array.from(list).forEach(element => {
              if (element && element !== null) {
                this.mailAddressTypes.push({
                  label: parseAddress({ address: element }).trim(),
                  value: parseAddress({ address: element }).trim(),
                  type: element.type.toLowerCase()
                })
              }
            })

            this.mailAddressTypes = this.mailAddressTypes.filter(f => f.label.length > 0)

            if (this.isDebug == true)
              console.debug('mailAddressTypes=' + JSON.stringify(this.mailAddressTypes))
          }
        })
    },

    async loadEmailContacts() {
      console.debug(
        'this.mailDelivery.ownerMailingDeliveryMethodID=' +
          this.mailDelivery.ownerMailingDeliveryMethodID
      )

      await ownerMailDeliveryStore
        .dispatch('getOwnerContactsSelectedAndUnSelectedList', {
          ownerMailingDeliveryMethodID: this.mailDelivery.ownerMailingDeliveryMethodID
        })
        .then(({ list }) => {
          if (list) {
            this.emailContacts = list
            let selected = []

            Array.from(this.emailContacts).forEach(e => {
              if (e) {
                if (e.isSelected) {
                  selected.push(e.ownerContact.ownerContactID)
                }
              }
            })

            this.checkboxTextGroup = selected

            if (this.isDebug == true)
              console.debug('emailContacts=' + JSON.stringify(this.emailContacts))
          }
        })
    },

    getPayload() {
      if (this.isDebug == true) console.debug('this.formData=' + JSON.stringify(this.formData))

      const payload = {
        ownerContactID: this.formData.ownerContactID,
        ownerID: this.ownerID,
        firstName: this.formData.FirstName,
        lastName: this.formData.LastName,
        emailAddressOne: this.formData.EmailAddressOne,
        phone: this.formData.Phone,
        workPhone: this.formData.WorkPhone,
        cellPhone: this.formData.CellPhone
      }

      if (this.isDebug == true) console.debug('payload=' + JSON.stringify(payload))

      return payload
    },
    async onFormSubmit() {
      if (!this.formData || this.formData == undefined) {
        notifyError('There was a problem submitting your form.')
        return
      }

      console.debug('...this.formData.deliverToAddress=' + JSON.stringify(this.formData))

      this.loading = true

      if (this.buttonText === 'Add') {
        try {
          await ownerMailDeliveryStore.dispatch('createMailDeliveryPreferences', {
            ownerID: this.ownerID,
            mailingTypeID: this.mailDelivery.mailingTypeID || null,
            deliveryType: this.formData.deliverBy ? this.formData.deliverBy.toLowerCase() : null,
            mailingAddressType:
              this.formData.deliverToAddress && this.formData.deliverToAddress.type
                ? this.formData.deliverToAddress.type.toLowerCase()
                : null,
            done: () => {
              // auto-close modal
              this.$emit('update:toggle', (this.toggle = false))
              $('#clickclose').click()
              this.loading = false
            }
          })
        } catch (e) {
          // If this is a validation error, get the details for each field
          if (e instanceof ValidationError) {
            this.$refs.form.setErrors(e.fields)
          }

          notifyError('There was a problem saving these preferences.')
        }
      } else {
        //Update existing record
        const payload = {
          ownerMailingDeliveryMethodID: this.mailDelivery.ownerMailingDeliveryMethodID,
          ownerID: this.ownerID,
          mailingTypeID: this.mailDelivery.mailingTypeID || null,
          deliveryType: this.formData.deliverBy ? this.formData.deliverBy.toLowerCase() : null,
          mailingAddressType:
            this.formData.deliverToAddress && this.formData.deliverToAddress.type
              ? this.formData.deliverToAddress.type.toLowerCase()
              : null
        }

        await ownerMailDeliveryStore.dispatch('updateMailDeliveryPreferences', {
          payload: payload,
          done: async ({ details }) => {
            if (details && payload.deliveryType === 'email') {
              //delete first
              Array.from(this.emailContacts).forEach(async e => {
                if (e && e.isSelected === true && e.ownerMailingDeliveryMethodEmailContactID > 0) {
                  console.debug('delete first=' + JSON.stringify(e))
                  //delete by id
                  await ownerMailDeliveryStore.dispatch(
                    'deleteOwnerMailingDeliveryMethodEmailContact',
                    {
                      ownerMailingDeliveryMethodEmailContactID:
                        e.ownerMailingDeliveryMethodEmailContactID,
                      done: () => {}
                    }
                  )
                }
              })

              let messages = ''
              Array.from(this.checkboxTextGroup).forEach(async ownerContactID => {
                if (ownerContactID && ownerContactID > 0) {
                  //insert selected email contacts
                  try {
                    await ownerMailDeliveryStore.dispatch(
                      'createOwnerMailingDeliveryMethodEmailContact',
                      {
                        ownerMailingDeliveryMethodID: this.mailDelivery
                          .ownerMailingDeliveryMethodID,
                        ownerContactID: ownerContactID,
                        done: () => {
                          messages = 'Successfully saved your email preferences.'
                        }
                      }
                    )
                  } catch (e) {
                    // If this is a validation error, get the details for each field
                    notifyError('There was a problem saving your email preferences.')
                    return
                  }
                }
              })

              notifyMessage(messages)
            }
            // auto-close modal
            this.$emit('update:toggle', (this.toggle = false))
            $('#clickclose').click()
            this.loading = false
          }
        })
      }

      this.loading = false
      this.toggle = false
      this.formComplete = true
      this.formData = { ...DEFAULT_FORM_VALUES }
      if (this.$refs.form) {
        this.$refs.form.reset()
      }
    }
  }
}
</script>

<style lang="scss">
#mail-delivery-modal.step-details {
  background-color: #fff !important;
  padding-left: 0.25rem !important;
  padding-right: 0.5rem !important;
}

.steps.b-steps.is-small .steps {
  margin-top: 1rem;
}

#mail-delivery-modal.b-steps .steps + .step-content {
  padding-top: 0 !important;
}

#mail-delivery-modal.step-navigation {
  display: flex;
  justify-content: flex-end;
}

#mail-delivery-modal.maildeliverymodal.my-grid-class {
  height: 400px;
}

#mail-delivery-modal.tbody {
  min-height: 100px !important;
  overflow: auto !important;
}
</style>
