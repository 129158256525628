/*
  import { ownerMailDeliveryStore } from '@/services/OwnerMailingDeliveryMethod/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'
import { notifyProblem, notifyError, notifyMessage } from '@/services/notify'
import kms from '@/services/kms'

const isDebug = true

/* eslint-disable no-empty-pattern */
const actions = {
  /*
    const { list } = await ownerMailDeliveryStore.dispatch ('getOwnerMailDeliveryList', {
      ownerID
    });
  */
  async getDeliveryTypeList({}) {
    console.log('in getDeliveryTypeList...')
    try {
      const result = [
        { value: 'mail', label: 'Mail' },
        { value: 'email', label: 'Email' }
      ]

      if (isDebug == true) console.debug('getDeliveryTypeList log=' + JSON.stringify(result))

      return {
        list: result
      }
    } catch (exception) {
      notifyProblem(`The delivery type list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  async getMailAddressTypesList({}, { ownerID }) {
    console.log('in getMailAddressTypesList...')
    try {
      const result = await kms.get(
        '/Mailings/MailingDeliveryItem/GetMailDeliveryMailAndPropertyAddressList',
        {
          params: {
            ownerID
          }
        }
      )

      if (isDebug == true) console.debug('getMailAddressTypesList log=' + JSON.stringify(result))

      return {
        list: result.results
      }
    } catch (exception) {
      notifyProblem(`The owner mail delivery list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  async getOwnerMailDeliveryList({}, { ownerID }) {
    console.log('in getOwnerMailDeliveryList...')
    try {
      const result = await kms.get('/Roster/OwnerMailingDeliveryMethod/List', {
        params: {
          ownerID
        }
      })

      if (isDebug == true) console.debug('getOwnerMailDeliveryList log=' + JSON.stringify(result))

      return {
        list: result.results
      }
    } catch (exception) {
      notifyProblem(`The owner mail delivery list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  async getOwnerContactsSelectedAndUnSelectedList({}, { ownerMailingDeliveryMethodID }) {
    console.log('in getOwnerContactsSelectedAndUnSelectedList...')
    try {
      const result = await kms.get(
        '/Roster/OwnerMailingDeliveryMethodEmailContact/GetSelectedAndNonSelectedContactList',
        {
          params: {
            ownerMailingDeliveryMethodID
          }
        }
      )

      if (isDebug == true)
        console.debug('getOwnerContactsSelectedAndUnSelectedList log=' + JSON.stringify(result))

      return {
        list: result.results
      }
    } catch (exception) {
      notifyProblem(`The selected owner contacts list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  // Initialize
  async initializeMailDelivery({ dispatch }, { ownerID, done }) {
    if (isDebug == true) console.debug('...in initializeMailDelivery')
    try {
      const results = await kms.post(
        `/Roster/OwnerMailingDeliveryMethod/InitializeOwnerMailingDelivery?ownerID=${ownerID}`
      )

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results === true) {
        console.debug(`Successfully initialized the owner mailing delivery methods for this owner.`)
        done()
      } else {
        console.debug(
          'There was a problem initializing the owner mailing delivery methods for this owner.'
        )
      }
    } catch (exception) {
      console.debug(
        `There was a problem initializing the owner mailing delivery methods for this owner.`
      )
      console.debug(exception)
    }
  },

  async createMailDeliveryPreferences(
    { dispatch },
    { ownerID, mailingTypeID, deliveryType, mailingAddressType, done }
  ) {
    if (isDebug == true) console.debug('...in saveMailDeliveryPreferences')
    try {
      const results = await kms.post(`/Roster/OwnerMailingDeliveryMethod`, {
        ownerID,
        mailingTypeID,
        deliveryType,
        mailingAddressType
      })

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results && results.ownerMailingDeliveryMethodID) {
        console.debug(`Successfully saved the mail delivery preferences for this owner.`)
        done()
      } else {
        console.debug('There was a problem saving the mail delivery preferences for this owner.')
      }
    } catch (exception) {
      console.debug(`There was a problem saving the mail delivery methods for this owner.`)
      console.debug(exception)
    }
  },

  async updateMailDeliveryPreferences({ dispatch }, { payload = {}, done }) {
    try {
      const result = await kms.put(`/Roster/OwnerMailingDeliveryMethod`, payload)

      if (this.isDebug == true) console.debug('result=' + JSON.stringify(result))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (result.ownerMailingDeliveryMethodID > 0) {
        notifyMessage(`Successfully saved your mail delivery preferences.`)
        done({ details: result })
      } else {
        notifyError('There was a problem saving your mail delivery preferences.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem saving your mail delivery preferences.`)
      console.error(exception)
    }
  },

  async createOwnerMailingDeliveryMethodEmailContact(
    { dispatch },
    { ownerMailingDeliveryMethodID, ownerContactID, done }
  ) {
    if (isDebug == true) console.debug('...in createOwnerMailingDeliveryMethodEmailContact')
    try {
      const results = await kms.post(`/Roster/OwnerMailingDeliveryMethodEmailContact`, {
        ownerMailingDeliveryMethodID,
        ownerContactID
      })

      if (this.isDebug == true)
        console.debug('createOwnerMailingDeliveryMethodEmailContact=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results && results.ownerMailingDeliveryMethodEmailContactID) {
        console.debug(`Successfully saved the email delivery preferences for this owner.`)
        done()
      } else {
        console.debug('There was a problem saving the email delivery preferences for this owner.')
      }
    } catch (exception) {
      console.debug(`There was a problem saving the email delivery methods for this owner.`)
      console.debug(exception)
    }
  },

  async deleteOwnerMailingDeliveryMethodEmailContact(
    { dispatch },
    { ownerMailingDeliveryMethodEmailContactID, done }
  ) {
    try {
      const path = `/Roster/OwnerMailingDeliveryMethodEmailContact/${ownerMailingDeliveryMethodEmailContactID}?forceDelete=true`

      const results = await kms.delete(path)

      if (isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results) {
        console.debug(`Successfully deleted this Owner Mailing Delivery Method Email Contact.`)
        done()
      } else {
        console.debug('There was a problem deleting Owner Mailing Delivery Method Email Contact.')
      }
    } catch (exception) {
      console.debug(`There was a problem deleting Owner Mailing Delivery Method Email Contact.`)
      console.error(exception)
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const ownerMailDeliveryStore = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
