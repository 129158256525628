import moment from 'moment'

let DEFAULT_FORM_VALUES_INIT = {
  displayName: '',
  phone1: '',
  phone2: '',
  emailAddress1: '',
  emailAddress2: '',
  isSharing: false,
  homeownerDirectoryContactID: 0
}

export const data = () => ({
  formComplete: false,
  formData: {
    ...DEFAULT_FORM_VALUES_INIT
  },
  toggle: false,
  loading: true,
  isDebug: true,
  formatDateFn: function(value) {
    return value && value != undefined
      ? moment(value.toString(), 'YYYY/MM/DD HH:mm:ss')
          .format('MM-DD-YYYY')
          .toString()
      : ''
  }
})
