var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"is-size-4"},[_vm._v(" Edit Mail Delivery"),_c('span',{staticClass:"font-small is-size-6"},[_vm._v(_vm._s((" - " + (_vm.mailDelivery.mailingType.name))))])]),_c('div',{staticClass:"box is-12"},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('transition',{attrs:{"name":"fade","mode":"out-in","appear":""}},[_c('form',{staticClass:"form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onFormSubmit)}}},[_c('fieldset',[(_vm.deliveryTypes)?_c('div',[_c('b-select',{attrs:{"label":"Deliver By","placeholder":"Deliver By","vid":"deliverBy","title":"Deliver By","rules":"required","required":""},model:{value:(_vm.formData.deliverBy),callback:function ($$v) {_vm.$set(_vm.formData, "deliverBy", $$v)},expression:"formData.deliverBy"}},_vm._l((_vm.deliveryTypes),function(option){return _c('option',{key:option.value,domProps:{"value":option.value}},[_vm._v(_vm._s(option.label))])}),0)],1):_vm._e(),(_vm.showMail === true)?_c('div',{staticClass:"pt-5 pb-5"},[(_vm.mailAddressTypes)?_c('div',[_c('b-select',{attrs:{"label":"<- Select Deliver To Address ->","placeholder":"<- Select Deliver To Address ->","vid":"deliverToAddress","title":"Deliver To Address","rules":"required","required":""},model:{value:(_vm.formData.deliverToAddress),callback:function ($$v) {_vm.$set(_vm.formData, "deliverToAddress", $$v)},expression:"formData.deliverToAddress"}},_vm._l((_vm.mailAddressTypes),function(option){return _c('option',{key:option.value,domProps:{"value":option}},[_vm._v(_vm._s(option.label))])}),0)],1):_vm._e()]):_vm._e(),(_vm.showEmailContacts === true)?_c('div',[_c('div',{staticClass:"pt-5"},[_c('b-field',{attrs:{"label":"Email to Contacts","tabindex":"0","aria-label":"Email to Contacts"}},[(_vm.emailContacts && _vm.emailContacts != undefined)?_c('div',_vm._l((_vm.emailContacts),function(item,index){return _c('div',{key:index,staticClass:"column is-12 pl-5"},[(
                            item.ownerContact &&
                              item.ownerContact !== undefined &&
                              item.ownerContact.ownerContactID &&
                              item.ownerContact.ownerContactID !== undefined
                          )?_c('b-checkbox',{attrs:{"native-value":item.ownerContact.ownerContactID},model:{value:(_vm.checkboxTextGroup),callback:function ($$v) {_vm.checkboxTextGroup=$$v},expression:"checkboxTextGroup"}},[_vm._v(" "+_vm._s(item.ownerContact.emailAddressOne || '')+" ")]):_vm._e()],1)}),0):_vm._e()])],1)]):_vm._e(),_c('div',{staticClass:"pb-5"},[_c('button',{staticClass:"button is-primary is-rounded",attrs:{"type":"submit","disabled":_vm.loading}},[_vm._v(" "+_vm._s(_vm.buttonText)+" ")])])]),_c('fieldset',[_c('ValidationProvider',{attrs:{"vid":"providerErrors"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('div',{staticClass:"field"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1)])])]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }