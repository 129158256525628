<template>
  <div class="section">
    <div class="container">
      <div class="is-size-4">Add Vehicle</div>
      <div class="box is-12">
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
          <transition name="fade" mode="out-in" appear>
            <form @submit.prevent="handleSubmit(onFormSubmit)" class="form">
              <fieldset>
                <div class="column is-12">
                  <input type="hidden" vid="vehicleID" v-model="formData.vehicleID" />
                  <valid-input
                    style="width:50px !important;"
                    name="Year"
                    label="Year"
                    maxlength="50"
                    type="text"
                    vid="Year"
                    placeholder="Year"
                    spellcheck="true"
                    aria-label="Year"
                    rules="max:50"
                    class="is-small"
                    v-model="formData.Year"
                  />
                </div>
                <div class="column is-12">
                  <valid-input
                    style="width:50px !important;"
                    name="Make"
                    label="Make"
                    maxlength="50"
                    type="text"
                    vid="Make"
                    placeholder="Make"
                    spellcheck="true"
                    aria-label="Make"
                    rules="max:50"
                    class="is-small"
                    v-model="formData.Make"
                  />
                </div>
                <div class="column is-12">
                  <valid-input
                    style="width:50px !important;"
                    name="Model"
                    label="Model"
                    maxlength="50"
                    type="text"
                    vid="Model"
                    placeholder="Model"
                    spellcheck="true"
                    aria-label="Model"
                    rules="max:50"
                    class="is-small"
                    v-model="formData.Model"
                  />
                </div>
                <div class="column is-12">
                  <valid-input
                    style="width:50px !important;"
                    name="Color"
                    label="Color"
                    maxlength="50"
                    type="text"
                    vid="Color"
                    placeholder="Color"
                    spellcheck="true"
                    aria-label="Color"
                    rules="max:50"
                    class="is-small"
                    v-model="formData.Color"
                  />
                </div>
                <div class="column is-8 pb-6" v-if="statesList">
                  <valid-select
                    label="State"
                    placeholder="State"
                    vid="stateID"
                    v-model="formData.stateID"
                  >
                    <option
                      v-for="option in statesList"
                      :value="option.value"
                      :key="option.value"
                      >{{ option.label }}</option
                    >
                  </valid-select>
                </div>
                <div class="column is-12">
                  <valid-input
                    style="width:50px !important;"
                    name="LicensePlateNumber"
                    label="License Plate Number"
                    maxlength="8"
                    type="text"
                    vid="LicensePlateNumber"
                    placeholder="License Plate Number"
                    spellcheck="true"
                    aria-label="LicensePlateNumber"
                    rules="max:8"
                    class="is-small"
                    v-model="formData.LicensePlateNumber"
                  />
                </div>
                <div class="pb-5">
                  <button type="submit" :disabled="loading" class="button is-primary is-rounded">
                    {{ buttonText }}
                  </button>
                </div>
              </fieldset>
              <fieldset>
                <ValidationProvider vid="providerErrors" v-slot="{ errors }">
                  <div class="field">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </fieldset>
            </form>
          </transition>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
// Components
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { ValidationError } from '@/services/errors'
import { ToastProgrammatic as Toast } from 'buefy'
import kms from '@/services/kms'
import { mapState, mapGetters } from 'vuex'
import $ from 'jquery'

// components
import ValidInput from '@/components/inputs/ValidInput'
import ValidSelect from '@/components/inputs/ValidSelect'

import moment from 'moment'
//const today = moment()

function notifyError(e) {
  Toast.open({
    duration: 7000,
    message: e.message || e,
    position: 'is-bottom',
    type: 'is-danger'
  })
}

function notifyMessage(e) {
  Toast.open({
    duration: 7000,
    message: e.message || e,
    position: 'is-bottom',
    type: 'is-success'
  })
}

let DEFAULT_FORM_VALUES = {
  stateID: 0,
  vehicleID: 0,
  year: '',
  make: '',
  model: '',
  color: '',
  licensePlateNumber: ''
}

export const defaultDateFormat = 'MM/dd/yyyy'

export default {
  name: 'VehicleModal',

  props: {
    ownerID: Number,
    vehicle: Object
  },

  components: {
    ValidInput,
    ValidSelect,
    ValidationObserver,
    ValidationProvider
  },

  data: function() {
    return {
      formComplete: false,
      formData: {
        ...DEFAULT_FORM_VALUES
      },
      loading: false,
      formatDateFn: function(value) {
        return value != null ? moment(value, 'YYYY/MM/DD HH:mm:ss').format('MM-DD-YYYY') : ''
      },
      isDebug: true,
      selectedRow: null,
      buttonText: ''
    }
  },

  created() {},

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId
    }),
    ...mapGetters('unit', ['statesList'])
  },

  mounted() {
    this.loading = true
    this.$store.dispatch('unit/getStatesList')
    if (this.isDebug == true) console.debug('statesList=' + JSON.stringify(this.statesList))

    if (this.vehicle && this.vehicle != undefined) {
      if (this.isDebug == true) console.debug('vehicle=' + JSON.stringify(this.vehicle))

      //populate the form
      this.formData.vehicleID = this.vehicle.vehicleID
      this.formData.Year = this.vehicle.year
      this.formData.Make = this.vehicle.make
      this.formData.Model = this.vehicle.model
      this.formData.Color = this.vehicle.color
      this.formData.LicensePlateNumber = this.vehicle.licensePlateNumber
      this.formData.stateID =
        this.vehicle.state && this.vehicle.state != undefined ? this.vehicle.state.stateID : 0

      this.buttonText = 'Update'
    } else {
      this.formData = { ...DEFAULT_FORM_VALUES }
      this.$refs.form.reset()

      this.buttonText = 'Add'
    }

    this.loading = false
  },

  methods: {
    getPayload() {
      if (this.isDebug == true) console.debug('this.formData=' + JSON.stringify(this.formData))

      const payload = {
        vehicleID: this.formData.vehicleID,
        year: this.formData.Year && this.formData.Year != undefined ? this.formData.Year : null,
        make: this.formData.Make && this.formData.Make != undefined ? this.formData.Make : null,
        model: this.formData.Model && this.formData.Model != undefined ? this.formData.Model : null,
        color: this.formData.Color && this.formData.Color != undefined ? this.formData.Color : null,
        licensePlateNumber:
          this.formData.LicensePlateNumber && this.formData.LicensePlateNumber != undefined
            ? this.formData.LicensePlateNumber
            : null,
        licensePlateStateID: this.formData.stateID,
        ownerID: this.ownerID
      }

      if (this.isDebug == true) console.debug('payload=' + JSON.stringify(payload))

      return payload
    },
    async onFormSubmit() {
      if (
        !this.formData ||
        this.formData == undefined ||
        !this.ownerID ||
        this.ownerID == undefined ||
        this.ownerID <= 0
      ) {
        notifyError('There was a problem submitting your form.')
        return
      }

      this.loading = true
      try {
        const path = `/Roster/Vehicle`
        let results = null

        let message = ''

        if (this.formData.vehicleID > 0) {
          results = await kms.put(path, this.getPayload())
          message = 'updated'
        } else {
          results = await kms.post(path, this.getPayload())
          message = 'added'
        }

        if (this.isDebug == true) console.debug(JSON.stringify(results))

        if (results && results.vehicleID >= 0) {
          this.$emit('update:toggle', (this.toggle = !this.toggle))

          // auto-close modal
          $('#clickclose').click()

          notifyMessage(`The selected vehicle was successfully ${message}.`)
        } else {
          notifyError('There was a problem saving this vehicle.')
        }
      } catch (e) {
        // If this is a validation error, get the details for each field
        if (e instanceof ValidationError) {
          this.$refs.form.setErrors(e.fields)
        }

        notifyError('There was a problem saving this vehicle.')
      }
      this.loading = false
      this.toggle = false
      this.formComplete = true
      this.formData = { ...DEFAULT_FORM_VALUES }
      if (this.$refs.form) {
        this.$refs.form.reset()
      }
    }
  }
}
</script>

<style lang="scss">
.step-details {
  background-color: #fff !important;
  padding-left: 0.25rem !important;
  padding-right: 0.5rem !important;
}

.b-steps.is-small .steps {
  margin-top: 1rem;
}

.b-steps .steps + .step-content {
  padding-top: 0 !important;
}

.step-navigation {
  display: flex;
  justify-content: flex-end;
}

.my-grid-class {
  height: 400px;
}

tbody {
  min-height: 100px !important;
  overflow: auto !important;
}
</style>
